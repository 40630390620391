<template>
  <header id="header_dp">
    <div class="header_top">
      <div class="inner clearfix">
        <div class="mw320log">
          <div class="logowrap">
            <a class="block" :href="$root.linkHref('/')">
              <img class="logodpmain" src="../images/logo_derpart.svg" alt="DERPART Logo">
            </a>
          </div>
        </div>
        <div id="hira" onclick='ira._iraflyout(this,{"mid":1000},{left:-10,top:3});'>
          <div id="hirah"><span class="dtxt">Persönliche Wunschreise</span><span class="mtxt">Wunschreise</span></div>
          <div id="hiraa">Anfragen</div>
          <div id="hirai"></div>
        </div>
        <nav id="mainnav">
          <div class="mw320 clearfix">
            <span id="mobilenav" @click="$root.toggleMobileNav" class="mobilenav mtxtinline js_mobilenav"></span>
            <div class="hidden-xs">
              <ul class="nav_topmain_1">
                <li v-for="item in nav.topMain" class="nav_topmain_1_li">
                  <a class="nav_topmain_1_link"
                     :id="$root.linkId('nav_topmain_1_',item)"
                     :href="$root.linkHref(item)"
                     :target="$root.linkTarget(item)"><span>{{ item.l }}</span></a>
                </li>
              </ul>
            </div>
            <div class="clearfix">
            </div>
          </div>
        </nav>
        <navigation-mobile :nav="nav.mobile" ref="mobileNav"/>
      </div>
    </div>
    <div class="header_sub dtxtblock">
      <div class="inner">
        <div id="subnav">
          <ul class="nav_themetop_1">
            <li v-for="item in nav.themeTop">
              <a :href="$root.linkHref(item)" :target="$root.linkTarget(item)">
                <span>{{item.l}}</span>
              </a>
            </li>
          </ul>
          <div class="clear"></div>
        </div>
        <div id="subnavmore">
          <span class="right"><a id="nav_themennav" @click="$root.toggleThemeMore" href="javascript:void(0)">Mehr Reisen</a></span>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </header>
  <section id="nav_theme">
    <div id="nav_theme_wrap">
      <div id="nav_theme_list" class="inner">
        <ul class="nav_theme_1">
          <li v-for="item in nav.themeMore" class="nav_theme_1_li">
            <a :href="$root.linkHref(item)" :target="$root.linkTarget(item)">
              <span>{{item.l}}</span>
            </a>
          </li>
          <li class="clear"></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import navigationMobile from "@/components/navigation-mobile";

export default {
  name: "central-header",
  components:{navigationMobile},
  props:{
    tenant:{
      type: Object,
      required: true,
    },
    nav:{
      type:Object,
      required: true,
    },
  },
  data(){
    return {
      mobileNavOpen:false
    }
  },
}
</script>

<style scoped>

</style>